@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

select:disabled {
  opacity: 1 !important;
}

/* 
Change the icon at the right side of input date and time to white in dark mode
Source: https://stackoverflow.com/questions/63002686/how-do-i-change-the-color-of-the-small-clock-icon-that-comes-with-the-html-input 
*/
body.chakra-ui-dark input[type="date"]::-webkit-calendar-picker-indicator,
body.chakra-ui-dark input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(100%);
}

div:has(> input[type="file"][aria-invalid="true"]) {
  border-color: #ee5d50;
  box-shadow: 0 0 0 1px #ee5d50;
}

body.chakra-ui-dark div:has(> input[type="file"][aria-invalid="true"]) {
  border-color: #fc8181;
  box-shadow: 0 0 0 1px #fc8181;
}
